import React from 'react'
import { breakpoints } from '@xstyled/system'
import styled, { css } from '@xstyled/styled-components'

const Box = styled.box(
  breakpoints({
    xs: css`
      height: 60px;
    `,
    md: css`
      height: 95px;
    `,
    lg: css`
      height: 260px;
    `,
  })
)

const Heading = styled.h1(
  () =>
    css`
      color: #ffffff;
      cursor: default;
      margin: 0;
      font-family: heading;

      ${breakpoints({
    xs: css`
          font-size: 22px;
          letter-spacing: 0.4px;
        `,
    md: css`
          font-size: 32px;
          letter-spacing: 3.2px;
        `,
    lg: css`
          font-size: 64px;
          letter-spacing: 6.4px;
        `,
  })}
    `
)

const PageHeader = ({ children }) => (
  <Box
    bg="secondary"
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    <Heading>{children}</Heading>
  </Box>
)

export default PageHeader
