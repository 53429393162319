import React from 'react'
import styled, { Box, css } from '@xstyled/styled-components'
import { breakpoints } from '@xstyled/system'
import blueSquiggle from '../images/blue-squiggle.svg'
import yellowSquiggle from '../images/yellow-squiggle.svg'

const file = (variant) => {
  switch (variant) {
    default:
    case 'yellow':
      return yellowSquiggle
    case 'blue':
      return blueSquiggle
  }
}

const StyledSquiggleBox = styled(Box)(
  ({ theme, variant, positioning }) => css`
    background-image: url(${file(variant)});
    background-repeat: no-repeat;
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    height: 100%;

    ${breakpoints({
    xs: css`
        display: none;
      `,
    md: css`
        left: ${positioning.md === 'left' ? `calc(${theme.breakpoints.md}px / 8 / 2)` : 'auto'};
        right: ${positioning.md === 'right' ? `calc(${theme.breakpoints.md}px / 8 / 2)` : 'auto'};
        background-position: top ${positioning.md};
        width: 100%;
        display: block;
      `,
    lg: css`
        left: ${positioning.lg === 'left' ? `calc(${theme.breakpoints.xl}px / 8 / 2)` : 'auto'};
        right: ${positioning.lg === 'right' ? `calc(${theme.breakpoints.xl}px / 8 / 2)` : 'auto'};
        right: 0;
        background-position: top ${positioning.lg};
      `,
  })}
  `
)

const SquiggleBox = ({variant = 'yellow', positioning = {md: 'right', lg: 'left'}, children, ...props}) => <StyledSquiggleBox variant={variant} positioning={positioning} {...props}>{children}</StyledSquiggleBox>

export default SquiggleBox
