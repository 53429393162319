import React from 'react'
import { breakpoints, system } from '@xstyled/system'
import styled, { css } from '@xstyled/styled-components'

const StyledSubHeading = styled.h2(
  ({ textAlign }) =>
    css`
      ${system}
      color: body;
      cursor: default;
      font-family: heading;
      line-height: 1.4em;
      text-align: ${textAlign ?? 'center'};

      ${breakpoints({
    xs: css`
          font-size: 20px;
          margin-bottom: 15px;
        `,
    md: css`
          font-size: 22px;
          text-align: ${textAlign ?? 'left'};
          margin-bottom: 29px;
        `,
    lg: css`
          font-size: 45px;
          text-align: ${textAlign ?? 'center'};
          margin-bottom: 54px;
        `,
  })}
    `
)

const SubHeader = ({ children, ...props }) => (
  <StyledSubHeading {...props}>{children}</StyledSubHeading>
)

export default SubHeader
